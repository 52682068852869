// Icon Import
import VMIcon from '@/assets/images/home-page/VM-icon.svg'
import PACIcon from '@/assets/images/home-page/PAC-icon.svg'
import PIIcon from '@/assets/images/home-page/PI-icon.svg'
import InspectorIcon from '@/assets/images/home-page/Inspector-icon.svg'
import HomeIcon from '@/assets/images/home-page/Home-icon.svg'

// BOM Validation page
export const BOM_VALIDATIONS_ROUTE = Object.freeze({
  name: 'bomValidations',
  path: 'bom',
  title: 'Daily BOM Validations'
})
export const BOM_VALIDATION_RESULTS_ROUTE = Object.freeze({
  name: 'bomValidationResults',
  path: 'bom/:id',
  title: 'BOM Validation results'
})

// Homepage page
export const HOME_ROUTE = Object.freeze({
  name: 'home',
  path: '/',
  title: 'Home',
  icon: HomeIcon
})

// Not Authorized page
export const NOT_AUTHORIZED_ROUTE = Object.freeze({
  name: 'notAuthorized',
  path: '/not-authorized',
  title: 'Not Authorized'
})

// Not found page
export const NOT_FOUND_ROUTE = Object.freeze({
  name: 'notFound',
  path: '/:pathMatch(.*)*',
  title: 'Page not found'
})

// Not allowed page
export const NOT_ALLOWED_ROUTE = Object.freeze({
  name: 'notAllowed',
  path: '/not-allowed',
  title: 'Page not allowed'
})

// Portfolio assistant configuration (PAC)
export const PAC_ROUTE = Object.freeze({
  name: 'pac',
  path: '/pac',
  title: 'Portfolio Assistant Config.'
})

// Inspector
export const INSPECTOR_ROUTE = Object.freeze({
  name: 'inspector',
  path: '/inspector',
  title: 'Inspector'
})

export const PAC_CONFIGURATIONS_ROUTE = Object.freeze({
  name: 'pacConfigurations',
  path: 'configurations'
})

export const PAC_CONFIGURATION_ROUTE = Object.freeze({
  name: 'pacConfiguration',
  path: 'configurations/:id'
})

// Product models page
export const PRODUCT_MODELS_ROUTE = Object.freeze({
  name: 'productModels',
  path: 'product-models',
  title: 'Product Models'
})

export const PRODUCT_MODEL_ROUTE = Object.freeze({
  name: 'productModel',
  path: ':encodedBusinessName'
})

// Search page
export const SEARCH_ROUTE = Object.freeze({
  name: 'search',
  path: 'search',
  title: 'Search'
})
export const COMBINATIONS_ROUTE = Object.freeze({
  name: 'combinations',
  path: 'combinations',
  title: 'Combinations'
})

// On demand exports page
export const ON_DEMAND_EXPORTS_ROUTE = Object.freeze({
  name: 'OnDemandExports',
  path: 'on-demand-exports',
  title: 'On Demand Exports'
})

export const ON_DEMAND_BOM_VALIDATIONS_ROUTE = Object.freeze({
  name: 'OnDemandBomValidations',
  path: 'on-demand-exports/bom/:id/userExport/:userExportID',
  title: 'On Demand Exports'
})

// Test cases page
export const TEST_CASES_ROUTE = Object.freeze({
  name: 'testCases',
  path: 'test-cases',
  title: 'Daily Test cases'
})

export const TEST_CASE_VALIDATIONS_ROUTE = Object.freeze({
  name: 'testCaseValidations',
  path: ':testCaseId',
  title: 'Test case validations'
})

export const TEST_CASE_VALIDATION_RESULTS_ROUTE = Object.freeze({
  name: 'testCaseValidationResultsRoute',
  path: 'test-cases/:testCaseId/validations/:testCaseValidationId',
  title: 'Test case validation results'
})

export const TEST_CASE_VALIDATION_COMPARISON_ROUTE = Object.freeze({
  name: 'testCaseValidationComparisonRoute',
  path: 'test-cases/:testCaseId/validations/:mainValidationId/:comparedValidationId',
  title: 'Test case validation comparison'
})

export const UNUSABLE_CHOICES_VALIDATION_RESULTS_ROUTE = Object.freeze({
  name: 'unusableChoicesValidationResultsRoute',
  path: 'test-cases/unusable-choices/:validationId',
  title: 'Unusable choices validation results'
})

export const NOT_USUABLE_CHOICES_COMPARISON_ROUTE = Object.freeze({
  name: 'notUsableChoicesComparisonRoute',
  path: 'not-usable-choices/insights',
  title: 'Not usable choices comparison insights'
})

// Validations page
export const VALIDATIONS_ROUTE = Object.freeze({
  name: 'validations',
  path: '/validations',
  title: 'Validations'
})

// Rule Validations page
export const RULE_VALIDATIONS_ROUTE = Object.freeze({
  name: 'ruleValidations',
  path: '/rule-validation',
  title: 'Rule Validations'
})

// Variant management page
export const BROWSE_PORFOLIO_ROUTE = Object.freeze({
  name: 'browsePorfolio',
  path: '/browse-portfolio',
  title: 'Browse Portfolio'
})

// Variant management page
export const VARIANT_MANAGEMENT_ROUTE = Object.freeze({
  name: 'variantManagement',
  path: '/variant-management',
  title: 'Variant Management'
})

// User Management page
export const USER_MANAGEMENT_ROUTE = Object.freeze({
  name: 'userManagement',
  path: '/user-management',
  title: 'User Management'
})

export const USER_FEEDBACK_ROUTE = Object.freeze({
  name: 'userFeedback',
  path: '/feedback',
  title: 'Report a missing feature'
})

export const SAVED_TABLES_ROUTE = Object.freeze({
  name: 'savedTables',
  path: '/saved-tables',
  title: 'Search'
})

export const SCHEDULES_ROUTE = Object.freeze({
  name: 'schedules',
  path: '/schedules',
  title: 'Manage schedules'
})

export const SCHEDULES_DETAILS_ROUTE = Object.freeze({
  name: 'schedulesDetails',
  path: '/schedules/:id/details',
  title: 'Manage schedules'
})

export const SCHEDULES_SEARCH_ROUTE = Object.freeze({
  name: 'schedulesSearch',
  path: 'search-elements',
  title: 'Search for elements'
})

// Application features routes
export const APP_FEATURES_ROUTES = Object.freeze({
  [VARIANT_MANAGEMENT_ROUTE.name]: {
    title: VARIANT_MANAGEMENT_ROUTE.title,
    children: [PRODUCT_MODELS_ROUTE, SEARCH_ROUTE, BROWSE_PORFOLIO_ROUTE, SCHEDULES_ROUTE],
    icon: VMIcon,
    id: 1
  },
  [VALIDATIONS_ROUTE.name]: {
    title: VALIDATIONS_ROUTE.title,
    icon: PIIcon,
    children: [BOM_VALIDATIONS_ROUTE, TEST_CASES_ROUTE, ON_DEMAND_EXPORTS_ROUTE],
    id: 2
  },
  [PAC_ROUTE.name]: {
    title: PAC_ROUTE.title,
    icon: PACIcon,
    id: 3
  },
  [RULE_VALIDATIONS_ROUTE.name]: {
    title: RULE_VALIDATIONS_ROUTE.title,
    icon: PIIcon,
    children: [BOM_VALIDATIONS_ROUTE, TEST_CASES_ROUTE, ON_DEMAND_EXPORTS_ROUTE],
    id: 4
  },
  [INSPECTOR_ROUTE.name]: {
    title: INSPECTOR_ROUTE.title,
    icon: InspectorIcon,
    id: 5
  }
})

// Application features routes
export const ACTIVE_APP_FEATURES_ROUTES = Object.freeze({
  [VARIANT_MANAGEMENT_ROUTE.name]: {
    title: VARIANT_MANAGEMENT_ROUTE.title,
    children: [PRODUCT_MODELS_ROUTE, SEARCH_ROUTE],
    icon: VMIcon,
    id: 1
  },
  [VALIDATIONS_ROUTE.name]: {
    title: VALIDATIONS_ROUTE.title,
    icon: PIIcon,
    children: [BOM_VALIDATIONS_ROUTE, TEST_CASES_ROUTE, ON_DEMAND_EXPORTS_ROUTE],
    id: 2
  },
  [PAC_ROUTE.name]: {
    title: PAC_ROUTE.title,
    icon: PACIcon,
    id: 3
  },
  [INSPECTOR_ROUTE.name]: {
    title: INSPECTOR_ROUTE.title,
    icon: InspectorIcon,
    id: 4
  }
})

// Internal features routes
export const INTERNAL_FEATURES_ROUTES = Object.freeze({
  [USER_MANAGEMENT_ROUTE.name]: {
    title: USER_MANAGEMENT_ROUTE.title
  },
  [USER_FEEDBACK_ROUTE.name]: {
    title: USER_FEEDBACK_ROUTE.title
  }
})
